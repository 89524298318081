@tailwind base;
@tailwind components;
@tailwind utilities;
.mainPhotobooth{
 background-color: #050d23;
  height: 100%;
}
.cardPhotobooth{
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.frameSection{
  background-image: url("./asset/photobooth.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}
.round{
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position:
    calc(100% - 20px) calc(2em + 2px),
    calc(100% - 15px) calc(2em + 2px),
    calc(100% - .5em) 1.5em;
  background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
}
select{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
a{
  margin: 0;
  padding: 0;
}
@media (max-width: 600px){
  .mainPhotobooth{
    height: 100vh;
  }
}